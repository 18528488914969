import React from 'react'
import { navigate } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

import './article-meta.scss'

const ArticleMeta = ({ article }) => (
  <>
    {article.frontmatter.category && <h3>{article.frontmatter.category}</h3>}
    <h2 onClick={() => navigate(article.fields.slug)}>{article.frontmatter.title}</h2>
    <ul>
      {article.frontmatter.author && <li><FontAwesomeIcon icon={faUser} /> {article.frontmatter.author}</li>}
      {article.frontmatter.date && <li><FontAwesomeIcon icon={faCalendarAlt} /><time dateTime={article.frontmatter.date}>{article.frontmatter.date}</time></li>}
    </ul>
  </>
)

export default ArticleMeta
