import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import './image-overlay.scss'

const ImageOverlay = ({ link, sizes, alt, heading, description, children, ...rest }) => (
  <Link to={link} className="c-image-overlay" {...rest}>  
    <Img sizes={sizes} className="c-image-overlay__image" />
    <div className="c-image-overlay__overlay">
      {children ? children : (
        <>
          {heading ? <h2>{heading}</h2> : ""}
          {description ? <p dangerouslySetInnerHTML={{__html: description}} /> : ""} 
        </>
      )}
    </div>
  </Link>
)

export default ImageOverlay
