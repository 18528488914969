import React from 'react'

import ImageOverlay from 'components/image-overlay'
import ArticleMeta from 'components/article-meta'

import './article-tile.scss'

const ArticleTile = ({ article }) => (
  <ImageOverlay 
    sizes={article.frontmatter.image.childImageSharp.fluid} 
    link={article.fields.slug}
    key={article.fields.slug}
  >
    <ArticleMeta article={article} />
  </ImageOverlay>
)

export default ArticleTile
